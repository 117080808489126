// jshint strict:false, undef:false, unused:false

/*/
	Crema Captcha Init
	Stephen Ginn at Crema Design Studio
	Updated 2020-12-09
/*/

(function () {
	// Crema Captcha 4.3.7
	captcha('[data-captcha]', {
        'action': 'https://formcarry.com/s/n9pYt8RWDNy'
	});

	contact({
		debug: true,
		loaded(settings) {
			console.log("We've initialized a FormCarry form!", this);
			console.log(settings);
		},
		success() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-gold";
		},
		error() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-danger";
		}
	});
})();

function toggleAnimations(){
	document.querySelector('.animate-bottle').classList.toggle('boat');
	[ ...document.querySelectorAll(".anchor")].forEach(ea => {
		ea.classList.toggle('rock-small');
	})
	document.querySelector('.sign').classList.toggle('rock');
};

document.querySelector('.animate-bottle').addEventListener("click", function() {
	toggleAnimations();
});

[...document.querySelectorAll(".anchor")].forEach( ea => {
	ea.addEventListener("click", function() {
		toggleAnimations();
	})
});

/*/ ==========================================================================
	Mobile Browser Menu
========================================================================== /*/

$("select.mobile-nav").change(function() {
    var data_target = $(this).find("option:selected").val();
  	$('a[href="' + data_target + '"]').tab('show');
});
